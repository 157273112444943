.popup_overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #00000095;
    z-index: 1011;
}
.drawer_popup_wrapper {
    position: fixed;
    left: 50%;
    top: 50%;
    width: 800px;
    height: 600px;
    background: #fff;
    z-index: 1012;
    overflow: scroll;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 0;
    z-index: 150;
}

.drawer_popup_wrapper .headerPopup {
    background: #f5f5f5;
    padding: 10px;
    margin-bottom: 10px;
}
.drawer_popup_wrapper_sidedrawer {
    z-index: 14440;
    position: fixed;
    right: 0;
    transform: translate(50% 50%);
    height: 100vh;
    top: 0px;
    width: 650px;
    background: #fff;
    box-shadow: 0px 0px 10px #00000090;
}
.headerPopup {
    margin: 0px -10px;
    background: #f5f5f5;
    padding: 10px;
    margin-bottom: 10px;
}

.drawer_popup h1 {
    padding: 0;
    font-size: 18px;
    margin: 0;
    height: 40px;
    line-height: 40px;
}

.drawer_popup_wrapper_sidedrawer2 {
    z-index: 14440;
    position: fixed;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    top: 50%;
    max-width: 650px;
    width: 100%;
    background: #fff;
    box-shadow: 0px 0px 10px #00000090;
    max-height: 100vh;
}

.drawer_popup_wrapper_sidedrawer2 .headerPopup {
    margin: 0px;
}
.drawer_popup_wrapper_sidedrawer2  .popoverInner{ max-height: calc(100vh - 100px); overflow: auto;}